// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$quantifly-primary: mat-palette($mat-indigo);
$quantifly-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$quantifly-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$quantifly-theme: mat-light-theme(
  (
    color: (
      primary: $quantifly-primary,
      accent: $quantifly-accent,
      warn: $quantifly-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($quantifly-theme);

/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@config "../tailwind.config.js";

.card {
  @apply bg-white rounded-lg;

  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);

  &-title {
    @apply font-semibold text-xl tracking-tight leading-loose;
  }

  &-content {
    @apply p-6;
  }

  @screen md {
    &-content {
      @apply p-8;
    }
  }
}

.highlight {
  @apply font-semibold text-sm tracking-tight leading-normal text-primary;
}

.btn:disabled {
  @apply cursor-not-allowed;
  background: theme("colors.gray-light");
}

.btn {
  @apply p-3 rounded font-semibold text-sm border;
  transition: all 0.2s;

  &-primary {
    @apply text-white bg-primary;
    background-image: theme("colors.liner-gradiant-black");

    &:hover:enabled {
      background-image: theme("colors.liner-gradiant-black");
      @apply bg-primary-hover;
    }
  }

  &-primary-outline {
    @apply text-primary border border-primary;

    &:disabled {
      @apply bg-transparent border-gray-light text-gray-light;
    }

    &:hover:enabled {
      @apply bg-primary-transparent;
    }
  }

  &-primary-selected {
    @apply text-primary border border-primary bg-primary-transparent;
  }

  &-primary-link {
    @apply text-primary font-bold border-transparent;

    &:hover:enabled {
      @apply text-primary-hover;
    }
  }

  &-gray-light-outline {
    @apply text-primary border border-gray-light;

    &:hover,
    &:focus {
      @apply bg-primary-transparent border-primary;
    }

    &:disabled {
      @apply bg-transparent border-gray-light text-gray-light;
    }
  }

  &-empty {
    @apply text-gray-dark;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      @apply text-primary bg-gray-lighter;
      transition: 0s linear;
    }
  }
}

input[type="text"],
input[type="number"],
textarea {
  @apply border rounded border-gray-light p-3 text-black w-full;
}

input[type="range"] {
  @apply w-full cursor-pointer;
  height: 25px;
}

//  Reset range
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]:hover::-webkit-slider-thumb {
  border: 3px solid theme("colors.primary-hover");
}

input[type="range"]:hover::-moz-range-thumb {
  border: 3px solid theme("colors.primary-hover");
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  border: 3px solid theme("colors.primary");
  height: 32px;
  width: 38px;
  border-radius: 100px;
  background: url("assets/images/icn_slider_arrows.png") center/contain
      no-repeat content-box,
    theme("colors.primary-transparent-full");
  transition: all 0.2s;
  background-size: 22px 16px;
  box-shadow: 0 2px 10px rgba(12, 139, 226, 0.15);
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

input.input-invalid {
  outline: 1px solid #ef1f1f;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  border: 3px solid theme("colors.primary");
  height: 28px;
  width: 34px;
  border-radius: 100px;
  background: url("assets/images/icn_slider_arrows.png") center/contain
      no-repeat content-box,
    theme("colors.primary-transparent-full");
  transition: all 0.2s;
  background-size: 22px 16px;
  box-shadow: 0 2px 10px rgba(12, 139, 226, 0.15);
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  border: 4px solid theme("colors.primary");
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background: theme("colors.primary-transparent-full");
  box-shadow: none;
  cursor: pointer;
}

//  Range track
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: theme("colors.primary");
  border-radius: 4px;
  transition: all 0.2s;
}

input[type="range"]:focus::-moz-range-track {
  background: theme("colors.primary-hover");
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: theme("colors.primary-hover");
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: theme("colors.primary");
  border-radius: 4px;
  transition: all 0.2s;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: theme("colors.primary");
  border-radius: 4px;
  transition: all 0.2s;
}

input[type="range"]::-ms-fill-lower {
  background: theme("colors.primary");
  border-radius: 4px;
  box-shadow: none;
}

input[type="range"]:focus::-ms-fill-lower {
  background: theme("colors.primary-hover");
}

input[type="range"]::-ms-fill-upper {
  background: theme("colors.primary");
  border-radius: 4px;
  box-shadow: none;
}

input[type="range"]:focus::-ms-fill-upper {
  background: theme("colors.primary-hover");
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

body {
  margin: 0;
}

.mat-dialog-container {
  border-radius: 0.75rem !important;
  height: auto !important;
}

.mat-option {
  height: auto !important;
  min-height: 48px !important;
}

input[type="radio"] {
  accent-color: theme("colors.primary");
  top: 1px;
  left: -10px;
  font-size: 0px;
  position: relative;
  display: inline-grid;
  transform: scale(0.5);
  margin-right: -8px !important;
  align-items: center;
  flex-direction: row;
  align-content: flex-end;
}
input[type="radio"]:after {
  width: 28px;
  height: 28px;
  border-radius: 16px;
  top: 6px;
  left: 0px;
  position: relative;
  background-color: theme("colors.white");
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid theme("colors.primary");
}

input[type="radio"]:checked:after {
  width: 28px;
  height: 28px;
  border-radius: 16px;
  top: 6px;
  left: 0px;
  position: relative;
  background-color: theme("colors.primary");
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid theme("colors.white");
}

.ribbon-content {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 159px;
  height: 159px;
  overflow: hidden;
  z-index: 100;
}

.ribbon {
  animation: blinkAndColorChange 2s linear infinite;
  display: inline-block;
  position: absolute;
  padding: 7px 0;
  left: -35px;
  top: 52px;
  width: 255px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  background: theme("colors.primary");
  color: #fff;
  font-weight: 700;
  transform: rotate(45deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.ribbon-text {
  animation: blink 2s linear infinite;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  content: "";
  border-top: 4px solid theme("colors.primary-hover");
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  bottom: -4px;
}

.ribbon::before {
  left: 14px;
}

.ribbon::after {
  right: 18px;
}

.bg-test {
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}
@keyframes blinkAndColorChange {
  0%,
  100% {
    background: theme("colors.primary");
  }
  50% {
    background: theme("colors.primary-hover");
  }
}

.numberValue {
  @apply text-[11px] w-5 h-5 justify-center items-center flex rounded-full font-semibold text-primary;
  margin-top: -26px;
  margin-left: -8px;
}
